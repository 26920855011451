import React from "react"

const ServicesHero = ({ text }) => {
  return (
    <section className="services--hero">
      <h1 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M60-regular">
        {text?.split("").map((letter, i) => (
          <span key={`letter-${i}`}>{letter}</span>
        ))
        }
      </h1>
    </section>
  )
}

export default ServicesHero
