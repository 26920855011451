import React, { useRef, useState, useEffect } from 'react'
import classNames from "classnames"

import Cta from "./cta"

const OpacityText = ({ content, containerClassName, title, cta }) => {
  const
    paragraphRef = useRef(),
    isVisible = useOnScreen(paragraphRef);

  function useOnScreen(ref) {
    const [isOnScreen, setIsOnScreen] = useState(false);
    const observerRef = useRef(null);

    useEffect(() => {
      observerRef.current = new IntersectionObserver(([entry]) =>
        setIsOnScreen(entry.isIntersecting)
      );
    }, []);

    useEffect(() => {
      observerRef.current.observe(ref.current);

      return () => {
        observerRef.current.disconnect();
      };
    }, [ref]);

    return isOnScreen;
  }

  const getClasses = (isBold, isVisible) => {
    let classes = classNames({
      "animate": isBold === false && isVisible === true,
      "bold": isBold === true
    })

    return classes
  }

  return (
    <section className={`${containerClassName} opacity-type`}>
      {!!title && (
        <p className="title">{title}</p>
      )}
      <p ref={paragraphRef}>
        {content?.map(string => {
          const { isBold, translate, _key} = string
          return (
            <span className={getClasses(isBold, isVisible)} key={_key}>{translate}</span>
          )
        })}
      </p>
      {!!cta && (
        <Cta cta={cta}/>
      )}
    </section>
  )
}

export default OpacityText
