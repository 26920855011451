import React from "react"

const Service = ({ title, text, list }) => {

  return (
    <section className="service-container">
      <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M60-regular">{title}</h3>
      <p data-sal='fade' data-sal-duration='500' data-sal-easing='ease'>{text}</p>
      <ul data-sal='fade' data-sal-duration='500' data-sal-easing='ease'>
        {
          list?.map((el, i) => (
            <li key={`sl-${i}`} className="M16-regular">{el}</li>
          ))
        }
      </ul>
    </section>
  )
}

export default Service
