import React, { useState } from "react"
import classNames from "classnames"
import Listening from "../../assets/svg/listening.svg"
import Simplifying from "../../assets/svg/simplifying.svg"
import Transforming from "../../assets/svg/transforming.svg"
import Defying from "../../assets/svg/defying.svg"
import Manifesting from "../../assets/svg/manifesting.svg"

const Process = ({ title, steps }) => {
  const [active, setActive] = useState(null)

  const getClasses = (i, active) => {
    let classes = classNames({
      "container": active === null,
      "container active": active === i,
      "container inactive": active !== null && active !== i,
    })

    return classes
  }

  return (
    <section className="services--process">
      <p data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M16-regular">{title}</p>
      <div className="shapes-container">
        <Listening className={`listening ${active === 0 ? 'animate' : ''}`} />
        <Simplifying className={`simplifying ${active === 1 ? 'animate' : ''}`} />
        <Transforming className={`transforming ${active === 2 ? 'animate' : ''}`} />
        <Defying className={`defying ${active === 3 ? 'animate' : ''}`} />
        <Manifesting className={`manifesting ${active === 4 ? 'animate' : ''}`} />
      </div>
      <ul className="steps-container">
        {steps?.map(({ _key, name, text }, i) => {
          return (
            <li key={_key}>
              <div className={getClasses(i, active)} onMouseEnter={() => setActive(i)} onMouseLeave={() => setActive(null)}>
                <span className="info">
                  <h4 className="M24-light">{name}</h4>
                  <p>{text}</p>
                </span>
              </div>
            </li>
          )})}
      </ul>
      <ul className="process-mobile">
        {steps?.map(({ _key, name, text }, i) => {
          return (
            <li key={_key}>
              <h4 className="M24-light">{name}</h4>
              <p>{text}</p>
            </li>
          )})}
      </ul>
    </section>
  )
}

export default Process
