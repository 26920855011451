import React from "react";

const BoldParagraph = ({ content, containerClassName, elemClassName }) => (
  <section className={`${containerClassName} animate-type`}>
    <p data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className={elemClassName}>
      {content?.map(string => {
        const { isBold, translate } = string
        return (
          <span className={isBold ? "bold" : ""} key={`boldP-${translate}`}>{translate}</span>
        )
      })}
    </p>
  </section>
)

export default BoldParagraph
