import React from "react"

import BoldParagraph from "../boldParagraph"

import Pl from "../../assets/svg/pl_logo.svg"
import Arrow from "../../assets/svg/cta_arrow.svg"

const ProsperLab = ({ text, cta }) => {
  return (
    <section data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="grid-16 services--prosperlab">
      <Pl className="pl-logo"/>
      <BoldParagraph containerClassName="prosperlab-text" content={text} elemClassName="TT28-elight"/>

      <a className="cta" target="__blank" href={cta.route}>{cta.title}<Arrow className="arrow"/></a>
    </section>
  )
}

export default ProsperLab
