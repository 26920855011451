import React from "react"

import BoldParagraph from "./boldParagraph"
import Cta from "./cta"

const HalfBlock = ({ title, text, cta }) => (
  <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="half-block">
    <h6>{title}</h6>
    <BoldParagraph content={text} containerClassName="half-cont" elemClassName="TT28-elight"/>

    {!!cta?.title && (
      <Cta cta={cta}/>
    )}
  </div>
)

export default HalfBlock
