import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { localize } from "../lib/helpers"
import { window } from "browser-monads"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ServicesHero from "../components/Services/hero"
import VideoNoControls from "../components/videoNoControls"
import HalfBlock from "../components/halfBlock"
import Process from "../components/Services/process"
import Image from "../components/image"
import OpacityText from "../components/opacityText"
import Service from "../components/Services/service"
import ProsperLab from "../components/Services/prosperlab"

import Services from "../assets/svg/services.svg"
import ServicesM from "../assets/svg/services_m.svg"

const ServicesPage = ({ data, pageContext, location }) => {
  const
    { _rawTitle, _rawHero, _rawBlockVideo, _rawBlockTitle, blockText, _rawBannerTitle,
    _rawSteps, banner, _rawApproachTitle, approach, _rawServicesTitle, _rawServices,
    plText, _rawCtaPl } = localize(data.sanityServices, [pageContext.language]);

    useEffect(() => {
      const containers = document.querySelectorAll('.services--banner')

      window.onscroll = (ev) => {
        containers.forEach((e) => {
          if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
            let t1 = Math.round(1e5 * (((window.scrollY || window.pageYOffset) - window.innerHeight) / window.innerHeight * 4 + 0.5)) / 1e5;
            if(!!e.querySelector(".serv-img")) {
              e.querySelector(".serv-img").style.transform = `translateY(${3 * t1}%)`
            }
          }
        })
      }
    })

  return (
    <Layout lang={pageContext.language}>
      <Seo title={_rawTitle} lang={pageContext.language} location={location}/>

      <div id="services-c">
        <ServicesHero text={_rawHero}/>

        <div className="services--video">
          <VideoNoControls video={_rawBlockVideo.vimeoVideo.oEmbedData.html}/>
          <HalfBlock title={_rawBlockTitle} text={blockText}/>
        </div>

        <Process title={_rawBannerTitle} steps={_rawSteps}/>

        <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="services--banner">
          <Image image={banner.asset} className="serv-img"/>
        </div>


        <OpacityText containerClassName="services--opacity" content={approach} title={_rawApproachTitle}/>

        <section className="services">
          <div className="bottom">
            <Services className="services-background"/>
            <ServicesM className="services-background-m"/>
          </div>
          <div className="grid-16 top">
            <h2 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M60-regular">{_rawServicesTitle}</h2>
            {
              _rawServices?.map((service) => {
                return (
                  <Service key={service._key} title={service.name} text={service.text} list={service.list}/>
                  )
                })
              }
          </div>
        </section>

        <ProsperLab text={plText} cta={_rawCtaPl}/>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ServicesPage($language: String) {
    sanityServices {
      _rawTitle
      _rawHero
      _rawBlockVideo
      _rawBlockTitle
      blockText {
        translate(language: $language)
        isBold
      }
      _rawBannerTitle
      _rawSteps
      banner {
        asset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      _rawApproachTitle
      approach {
        translate(language: $language)
        isBold
      }
      _rawServicesTitle
      _rawServices
      plText {
        translate(language: $language)
        isBold
      }
      _rawCtaPl
    }
  }
`

export default ServicesPage
