import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import Arrow from "../assets/svg/cta_arrow.svg"

const CTA = ({ cta }) => {
  const { i18n } = useTranslation('common')
  return(
    <Link className="cta" to={`/${i18n.language}${cta.route}`}>{cta.title}<Arrow className="arrow"/></Link>
  )
}

export default CTA
